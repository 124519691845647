import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"



class About extends React.Component {
  render() {
    return (
          <Layout>
   <SEO title="Page two" />
    <Container className='red-b h-full' fluid>
<h1>H1 titiel sdjhgj ajhgajshgas ajhgasd</h1>
<h2>H1 titiel sdjhgj ajhgajshgas ajhgasd</h2>
<h3>H1 titiel sdjhgj ajhgajshgas ajhgasd</h3>
<h4>H1 titiel sdjhgj ajhgajshgas ajhgasd</h4>
<p>PPPPp titiel sdjhgj ajhgajshgas ajhgasd</p>


        </Container>
   </Layout>

    )
  }
}
export default About
